(function(){
	var alvo = $('[data-contar-itens="true"]');
	alvo.each(function(){
		var isso = $(this);
		var item = isso.find('.carousel-item');
		if(item.length == 1){
			isso.addClass('carousel-um-item-active');
		}else{
			isso.removeClass('carousel-um-item-active');
		}
	});
})();