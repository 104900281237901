(function() {
	var observer = new IntersectionObserver(function(entries) {
		$(entries).each(function(index, entry) {
			if(entry.isIntersecting) {
				var $this		= $(entry.target);
				var animation 	= $this.data('animate');

				$this
					.addClass(animation)
					.removeAttr('data-animate');

				observer.unobserve(entry.target);
			}
		});
	}, {
		rootMargin: '50px 50px 50px 50px'
	});

	$('[data-animate]').each(function() {
		observer.observe(this);
	});
})();
