(function() {
	var btn 	= $('.btn__menu__toggler');
	var menu 	= $('#main__menu, body');
	var topo 	= $('.topo');

	var active 	= 'main__topo--shown';

	btn.data('aria.expanded', false);

	btn.click(function(e) {
		e.preventDefault();

		toggleAria();
		menu.addClass(active);


		topo.append('<div class="menu__backdrop"></div>');

		var bck = $('.menu__backdrop');

		bck.click(function() {
			menu.removeClass(active);

			toggleAria();

			bck.fadeOut(600, function() {
				bck.remove();
			});
		});

		btn.blur();

	});

	function toggleAria() {
		var aria = !btn.data('aria.expanded');

		btn.attr('aria-expanded', aria);
		btn.data('aria.expanded', aria);
	}

})();
